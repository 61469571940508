@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&display=swap');

body {
    overflow-x: hidden;
}

a:link {
    color: black;
    text-decoration: none;
}

/* visited link */
a:visited {
    color: black;
    text-decoration: none;
}

/* mouse over link */
a:hover {
    color: hotpink;
}

/* selected link */
a:active {
    color: black;
    text-decoration: none;
}


h1 {
    font-size: 1.5em;
    font-family: 'Dosis', sans-serif;
}

h2 {
    font-size: 1.1em;
    font-family: 'Dosis', sans-serif;
}

p {
    font-family: 'Dosis', sans-serif;
    font-size: 1em;
}

text {
    font-family: 'ArialRoundedMTBold', 'Arial Rounded MT Bold';
    font-size: 11.115px
}

#laptop {
    font-family: 'ArialRoundedMTBold', 'Arial Rounded MT Bold';
    font-size: 34.268px
}

#cat {
    font-family: 'ArialRoundedMTBold', 'Arial Rounded MT Bold';
    font-size: 11.115px
}

#pot {
    font-family: 'ArialRoundedMTBold', 'Arial Rounded MT Bold';
    font-size: 24px
}

#birdhouse {
    font-family: 'ArialRoundedMTBold', 'Arial Rounded MT Bold';
    font-size: 11.115px
}

#frame1 {
    font-family: 'ArialRoundedMTBold', 'Arial Rounded MT Bold';
    font-size: 11.115px
}

#frame2 {
    font-family: 'ArialRoundedMTBold', 'Arial Rounded MT Bold';
    font-size: 11.115px
}


#mobile-drawing {
    padding-left: 10vw;
}

.svg {
    min-width: 300px;
    max-width: 700px;
}


.parent {
    margin-top: 5vh;
    display: grid;
    grid-template-columns: 30vw 30vw 20vw;
    grid-template-rows: 7vh 10vh 28vh 15vh auto auto 10vh;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-content: center;

}

.div0 {
    font-family: 'Dosis', sans-serif;
    text-align: center;
    grid-area: 1 / 1 / 1/ 2;
    padding-left: 3em;
}

.div1 {
    font-family: 'Dosis', sans-serif;
    grid-area: 2 / 1 / 3/ 2;
    align-items: center;
    text-align: center;
    font-size: 1em;
    padding-left: 3em;
}

.div1-mobile {
    margin: 10vw;
    grid-area: 2 / 1 / 3/ 2;
    padding: 1vh;
    padding-left: 12em;
    padding-bottom: 1.5em;
    background-color: aliceblue;
    font-size: 2.1vh;
    font-family: 'Dosis', sans-serif;
    font-weight: 400;
    text-align: center;
    font-stretch: expanded;
    margin-left: -9em;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    letter-spacing: 0.1em;
    line-height: 1.3em;

}

.div2 {
    grid-area: 1 / 2 / 5 / 4;
}

.div3 {
    font-family: 'Dosis', sans-serif;
    grid-area: 3 / 1 / 4 / 2;
    padding-top: 1vh;
    padding-left: 10em;
    background-color: aliceblue;
    font-size: 2.1vh;
    font-weight: 400;
    text-align: center;
    font-stretch: expanded;
    margin-left: -9em;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    letter-spacing: 0.1em;
    line-height: 1.3em;
}

.div4 {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    grid-area: 5 / 1 / 6 / 4;
    gap: 1em;
}

.div4-item {
    justify-content: center;
    flex-direction: column;
    grid-area: 6 / 1 / 6 / 4;
    min-height: 10vh;
    padding: 2em;
    margin: 10px;
}

.div4-portfolio-item {
    width: 370px;
    justify-content: center;
    font-family: 'Dosis', sans-serif;
    border: 2px solid aliceblue;
    border-radius: 1em;
    padding: 5px;
    margin: 10px;
}

.div4-portfolio {
    font-family: 'Dosis', sans-serif;
    flex-flow: row wrap;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    margin-bottom: 2em;
    grid-area: 6 / 1 / 6 / 4;
    gap: 2em;
}



.div5 {
    font-family: 'Dosis', sans-serif;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    grid-area: 8 / 1 / 8 / 4;
    gap: 0.5em;
    max-height: 30vh
}

.div6 {
    font-family: 'Dosis', sans-serif;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    grid-area: 9 / 1 / 9 / 4;
}

.second-menu-btn {
    font-family: 'Dosis', sans-serif;
    background-color: aliceblue;
    border-radius: 6px;
    border: 1px solid aliceblue;
    display: inline-block;
    cursor: pointer;
    color: #666666;
    font-size: 13px;
    padding: 2px 4px;
    margin: 3px;
    text-decoration: none;
}

.second-menu-btn:hover {
    color: hotpink;
}

.photo-id-btn {
    font-family: 'Dosis', sans-serif;
    background-color: whitesmoke;
    border-radius: 15px;
    border: 0px;
    display: inline-block;
    cursor: pointer;
    color: #666666;
    font-size: 12px;
    text-decoration: none;
    margin-top: 1em;
}

.photo-id-btn:hover {
    color: hotpink;
    background-color: pink;
}

.menu {
    cursor: pointer;
    line-height: 1.7;
}

.menu:hover {
    color: hotpink;
}



.img {
    position: relative;
    max-height: 500px;
    max-width: 90%;
}